<template>
  <div ref="product-list-shopping-bag" class="product-list-shopping-bag">
    <div class="product-list-shopping-bag__header">
      <p>Pesananmu:</p>
      <p class="qty">{{ cart_qty_total }} produk</p>
    </div>
    <table class="shopping-table">
      <tr v-for="(item, i) in products" :key="i">
        <td>
          <figure>
            <img :src="pickImage(item.raw.detail)" :alt="item.name" />
          </figure>
        </td>
        <td>
          <h4>{{ item.brand }}</h4>
          <h5 class="name">{{ item.name }}</h5>
          <h5 class="combination" v-html="setAttributeName(item)"></h5>
          <h5 class="price" v-html="convertPrice(item.raw.detail)"></h5>
        </td>

        <td>
          <div class="shopping-table__action">
            <div class="qty">
              <button class="minus" @click.prevent="$emit('minus-cart', item.raw.detail)">
                <img src="/static/img/icons/icon-minus-borderless-pink.svg" alt="Minus Icon" />
              </button>
              <input type="number" :value="item.qty" readonly />
              <button class="plus" @click.prevent="$emit('plus-cart', item.raw.detail)">
                <img src="/static/img/icons/icon-plus-borderless-pink.svg" alt="Plus Icon" />
              </button>
            </div>
            <button class="remove" @click.prevent="$emit('remove-cart', item)">
              <img src="/static/img/icons/icon-delete-pink.svg" alt="Remove Product Shopping Bag" />
            </button>
          </div>
        </td>
      </tr>
    </table>

    <ModalExceedMaxProducts v-if="modal_exceed_max_products" @close="modal_exceed_max_products = false" />
  </div>
</template>

<script>
export default {
  name: 'vmColaProductShoppingBag',
  components: {
    ModalExceedMaxProducts: () => import('@/components/vm-cola/snippets/modal/modal-exceed-max-products'),
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      modal_exceed_max_products: false,
    };
  },

  computed: {
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
  },

  watch: {
    products: {
      handler() {
        setTimeout(() => {
          if (this.$refs['product-list-shopping-bag']) {
            this.$emit('height', this.$refs['product-list-shopping-bag'].clientHeight);
          }
        }, 250);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    setAttributeName(item) {
      if (item.variant) {
        if (item.variant.indexOf('non_specify') == -1) {
          return item.variant;
        }
      } else {
        if (item.raw.is_pack && item.raw.pack_detail && item.raw.pack_detail.length) {
          const font_1 = `font-family:'brandontext-bold',sans-serif;`;
          const font_2 = `font-family:'Lato',sans-serif;`;
          const color_1 = `color:#000;`;
          const color_2 = `color:#8C8582;`;
          const color_3 = `color:#DA2A52;`;
          const array = item.raw.pack_detail;
          let result = `<div style="font-size:12px;margin-top:20px;${font_1}${color_3}">BUNDLE PACK:</div>`;

          result += `<ul style="font-size:12px;list-style:none;padding:0;margin:0;${font_2}">`;

          for (let i = 0; i < array.length; i++) {
            if (array[i].detail) {
              result += `<li><div style="${color_1}">${array[i].detail.name}</div><div style="${color_2}">${
                this.convertCombination(array[i].detail.combination, true)
                  ? this.convertCombination(array[i].detail.combination, true) + ` - `
                  : ``
              }${Number(array[i].quantity)}Pcs</div></li>`;
            }
          }

          result += `</ul>`;

          return result;
        } else {
          return this.convertCombination(item.raw.detail.combination);
        }
      }
    },
    convertCombination(combination, is_pack = false) {
      if (combination) {
        let combination_name = '';

        const attributes = combination.attributes ? combination.attributes : {};
        const group = Object.keys(attributes);

        for (let i = 0; i < group.length; i++) {
          if (group.length > 1) {
            const attr_name_1 =
              group[0].indexOf('non_specify') == -1
                ? group[0].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                  })
                : '';
            const attr_name_2 =
              group[1].indexOf('non_specify') == -1
                ? group[1].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                  })
                : '';
            const attr_1 = attr_name_1 ? (!is_pack ? attr_name_1 + ': ' : '') + attributes[group[0]].name + ', ' : '';
            const attr_2 = attr_name_2 ? (!is_pack ? attr_name_2 + ': ' : '') + attributes[group[1]].name : '';
            combination_name = attr_1 + attr_2;
          } else {
            if (group[0] && group[0].indexOf('non_specify') == -1) {
              const attr_name = group[0].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
              });
              combination_name = (!is_pack ? attr_name + ': ' : '') + attributes[group[0]].name;
            }
          }
        }

        return combination_name;
      }
    },
    convertPrice(data) {
      let el = ``;
      const font_1 = `font-family:'brandontext-regular',sans-serif;`;
      const font_2 = `font-family:'brandontext-bold',sans-serif;`;
      const color_1 = `color:#000;`;
      const color_2 = `color:#8C8582;`;
      const color_3 = `color:#DA2A52;`;
      const margin = `margin:0 5px;`;

      // const is_discount = false;
      // const discount = data.combination.price_after_discount;
      if (data.combination.price_after_discount) {
        if (
          data.combination.price_after_discount &&
          data.combination.deduction_type &&
          data.combination.price &&
          data.combination.price_after_discount != data.combination.price
        ) {
          el += `<span style="${font_1}${color_2}text-decoration:line-through;">${this.$options.filters.currencyRp(
            data.combination.price
          )}</span>`;
          el += `<span style="${font_2}${color_3}${margin}">${this.$options.filters.currencyRp(
            this.convertPriceNumber(data)
          )}</span>`;
          if (data.combination.deduction_type == 'percentage') {
            el += `<span style="${font_1}${color_3}">[-${data.combination.deduction_percentage}%]</span>`;
          } else if (data.combination.deduction_type == 'amount') {
            el += `<span style="${font_1}${color_3}">[-${this.$options.filters.currencyRp(
              data.combination.deduction_amount
            )}]</span>`;
          }
        } else {
          el = `<span style="${font_2}${color_1}">${this.$options.filters.currencyRp(
            this.convertPriceNumber(data)
          )}</span>`;
        }
      } else {
        el = `<span style="${font_2}${color_1}">${this.$options.filters.currencyRp(
          this.convertPriceNumber(data)
        )}</span>`;
      }

      return el;
    },
    convertPriceNumber(item) {
      return item.combination.price_after_discount ? item.combination.price_after_discount : item.combination.price;
    },
    pickImage(item) {
      let prdImg = item.images && item.images.length && item.images[0];

      if (!prdImg) {
        prdImg = item.combination && item.combination.images.length && item.combination.images[0];
      }

      if (!prdImg) {
        prdImg = item.image && item.image;
        Object.assign(item, { images: [prdImg] });
      }

      const defaultCombImg =
        item.default_combination && item.default_combination.images && item.default_combination.images.length;
      if (prdImg || defaultCombImg) {
        let imageCover;
        if (prdImg && item.images.findIndex((res) => res.is_cover) > -1) {
          imageCover = item.images.find((res) => res.is_cover).url;
        }
        if (
          defaultCombImg &&
          item.default_combination.images[0] &&
          item.default_combination.images.findIndex((res) => res && 'is_cover' in res && res.is_cover) > -1
        ) {
          imageCover = item.default_combination.images.find((res) => res.is_cover).url;
        }
        if (imageCover) {
          return imageCover;
        }
      }
      return 'https://placehold.it/100x100';
    },
    brokenImage(e) {
      e.target.src = 'https://placehold.it/100x100';
    },
    async adjustQty(i, type) {
      if (this.total_items >= 4 && type !== 'minus') {
        // this.$toasted.global.error('You cannot add more than 4 items.');
        this.modal_exceed_max_products = true;
        return;
      }

      if (type === 'plus' && this.product_list?.length) {
        const temp = this.carts[i];
        const prod_qty = temp.qty;
        const vm_product = get(temp, 'raw.detail.combination', null);
        if (vm_product && vm_product.stores?.length) {
          const store_id = await Cookies.get('store_location_id');
          const vm_product_stock = vm_product.stores.find((s) => s.id === store_id);

          if (vm_product_stock && vm_product_stock.stock <= prod_qty) {
            this.$toasted.global.show('The product exceeds the limit.');
            return;
          }
        }
      }

      const temp = this.carts[i];
      const product = {
        product: {
          id: temp.raw.id,
          combination_id: temp.raw.combination_id,
          quantity: 1,
        },
        action: type,
      };

      this.$store.commit('SET_GLOBAL_LOADING', true);
      await this.$store.dispatch('updateCart', { product, index: i, context: this }).finally(() => {
        this.$store.commit('SET_GLOBAL_LOADING', false);
      });
    },
    confirmRemoveCart(i) {
      // this.modal_confirm_remove_product = true;
      // this.remove_product_index = i;
    },
  },
};
</script>
